import { render, staticRenderFns } from "./StoreInfo.vue?vue&type=template&id=776620a2&scoped=true&"
import script from "./StoreInfo.vue?vue&type=script&lang=js&"
export * from "./StoreInfo.vue?vue&type=script&lang=js&"
import style0 from "./StoreInfo.vue?vue&type=style&index=0&id=776620a2&scoped=true&lang=css&"
import style1 from "./StoreInfo.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "776620a2",
  null
  
)

export default component.exports
import {QPageSticky,QSpinnerAudio,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPageSticky,QSpinnerAudio,QField})
