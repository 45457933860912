//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import location from "@/assets/storecomment/location.png";
export default {
  name: "StoreInfo",
  data() {
    return {
      location,
      loading: true,
      mobile:"",
      username:"",
      address:"",
      storeOption: [],
      storeInfo:{},
      roleList:[],
      storeCode: "",
      storeName:""
    };
  },

  created() {
    const that = this;
    this.getUserInfo();
    
    window.document.title = "门店信息";
  },
  activated() {
    window.document.title = "门店信息";
  },
  mounted() {
    window.document.title = "门店信息";
  },
  methods: {
    goPath(path){
      this.$router.push({path: path, query: {storeCode:this.storeCode,storeName:this.storeName}})
      // this.$router.push({path:path, query: {storeCode:'E001Z003',storeName:'报喜鸟E001Z003'}})
    },
    storeChange(value) {
      console.log(value);
      this.getStoreInfo(value)
      this.storeOption.map(v =>{
        if(v.storeId == value){
          this.storeName = v.text
        }
      })
    },
    getUserInfo() {
      this.loading = true;
      const that = this;
      this.axios
        .get('/' + this.$ajaxPrefix.consumer +"/v1/wx/cp/getUserInfo")
        .then((res) => {
          console.log(res);
           that.loading = false;
          if (res && res.data && res.data.data) {
            that.roleList = res.data.data.attr.roleList;
            let saleStore = res.data.data.attr.saleStore
            //格式化下拉列表参数
            saleStore.map(v =>{
              v.text = v.storeId + v.title
              v.value = v.storeId
            })
            that.storeOption = saleStore;
            that.mobile = res.data.data.mobile;
            that.username = res.data.data.username;
            that.storeCode = saleStore[0].storeId
            that.getStoreInfo(that.storeCode)
            that.storeName = saleStore[0].text
          }
        });
    },
    getStoreInfo(storeId) {
      const that = this;
      this.axios
        .get('/' + this.$ajaxPrefix.consumer +"/v1/store/pro/getByStoreId?storeId=" + storeId)
        .then((res) => {
          console.log(res);
            that.storeInfo = res.data
        });
    },
  },
};
